<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="手机号" prop="content">
				  <el-input v-model="form.content" placeholder="请输入手机号,一行一个" type="textarea" clearable rows="10" />
				  <small class="d-block text-secondary" style="line-height: 20px;">请输入登录手机号,一行一个</small>
				</el-form-item>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
export default {
	inject:['app'],
	mixins:[common],
	components: {
		GlobalTips,
		Breadcrumb,
	},
	data() {
		return {
			preUrl: 'ckqx',
			autoRequest: false,
			form:{
				content:'',
			},
			rules:{
               content: [
                 { required: true, message: '请输入手机号码', trigger: 'blur' }
               ],
			},
		}
	},
	mounted() {
		this.getCkqx()
	},
	methods:{
		getCkqx(){
			this.axios.get(`/manage/ckqx/info`, {
			  params: {}
			}).then(res=>{
			  if(res.status){
				this.form.content = res.data
			  }
			})
		},
		handleSubmit() {
		  this.$refs.form.validate(valid => {
		    if (valid) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('编辑成功')
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
	},
}
</script>

<style>
</style>